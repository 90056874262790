@font-face {
font-family: '__rocGrotesk_08c603';
src: url(/_next/static/media/e8b9fa78120ee662-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__rocGrotesk_08c603';
src: url(/_next/static/media/aab3cad41bb741b1-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__rocGrotesk_08c603';
src: url(/_next/static/media/33adcaaf9cfdb9c3-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__rocGrotesk_08c603';
src: url(/_next/static/media/4adfde107d27efa3-s.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__rocGrotesk_Fallback_08c603';src: local("Arial");ascent-override: 68.91%;descent-override: 21.76%;line-gap-override: 39.90%;size-adjust: 110.29%
}.__className_08c603 {font-family: '__rocGrotesk_08c603', '__rocGrotesk_Fallback_08c603'
}.__variable_08c603 {--font-roc: '__rocGrotesk_08c603', '__rocGrotesk_Fallback_08c603'
}

